<template>
  <div v-loading = "pageloading">
    <div style="padding-bottom:100px">
      <el-form :model="ruForm" :rules="rules" label-width="150px" ref="ruForm">
        <el-card>
          <div class = "headFont">基本信息</div>
          <div style="margin-top:20px">
            <el-form-item label="活动名称：" prop="ActivityName">
              <el-input :disabled="noedit" v-model="ruForm.ActivityName" maxlength="20" placeholder="请输入活动名称，最多20个字" style="width:350px"></el-input>
            </el-form-item>
            <el-form-item label="活动时间：" class = "ladder">
              <div class = "flexRow">
                <el-form-item prop="StartTime" :rules="activestate==0?rules.StartTime:[{required: false}]" label-width="0px">
                  <el-date-picker :disabled="noedit||activestate==1" type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="ruForm.StartTime" placeholder="开始时间" style="width:200px"
                  :picker-options="pickerOptions" @change = "changetime1"></el-date-picker>
                </el-form-item>
                <span style="margin:0px 10px">~</span>
                <el-form-item prop="EndTime"  label-width="0px">
                  <el-date-picker :disabled="noedit" type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="ruForm.EndTime" placeholder="结束时间" style="width:200px"
                  :picker-options="pickerOptions2" @change = "changetime2"></el-date-picker>
                </el-form-item>
              </div>
            </el-form-item>
          </div>
        </el-card>
        <el-card  style="margin-top:10px">
          <div class = "headFont">推广群聊</div>
          <div class = "blueBox">
            <div class = "blueBoxFont" style="width:80px;text-align:right">
              <p>提示：</p>
            </div>
            <div class = "blueBoxFont">
              <p>1.请前往企业微信后台—客户群中下载群聊二维码，并上传在活动配置页</p>
              <p>2.上传的群聊二维码需要和选择的群聊保持一致，否则客户扫码进入群聊后， <span class = "redFont">不会计为裂变成功，同时本次活动统计数据将会出现错误；</span></p>
              <p class = "grayFont2">示例：活动选择群聊是A群，上传的群二维码是B群，客户扫码进入B群后，不会计为裂变成功，同时，在活动统计数据中，该客户不会被计入通过活动加入的群聊等</p>
              <p>3.选择多个群聊参加活动，客户扫码进入页面，系统将根据活动中配置的顺序从前往后依次分配，达到群人数上限值后会推送后面的群聊二维码</p>
            </div>
          </div>
          <el-form-item prop="JoinGroupList" label-width="0px">
            <draggable :list="ruForm.JoinGroupList" :options="{animation: 100, handle:'.drap'}"
              :scroll="true" scrollSensitivity="500" class = "groupbox">
              <div class = "groupcontentbox" v-for="(v,i) in ruForm.JoinGroupList" :key="i">
                  <div class = "groupcontent-top">
                    <el-form-item class = "qrcode-err" label-width="0px" :prop="'JoinGroupList.'+i+'.QrCode'" :rules="rules.QrCode" style="margin-top:15px">
                      <el-upload :disabled="noedit" :action="imgApi"  :show-file-list="false" class="avatar-uploader"
                      :on-success="($event)=>{return handleAvatarSuccess($event,i)}" :before-upload="($event)=>{return beforeAvatarUpload($event,i)}">
                        <i class="el-icon-plus avatar-uploader-icon">
                          <span style="font-size:16px">上传群码</span>
                        </i>
                      </el-upload>
                      <img v-if="v.QrCode" :src="imgUrl+v.QrCode" class="avatar" />
                    </el-form-item>
                    <div class = "groupcontent-top-right">
                      <div class = "flowhidden" >
                        <div class = "successstyle nosuccesss" v-if="v.ExtensionState==0">未开始</div>
                        <div class = "successstyle" v-else-if="v.ExtensionState==1">推广中</div>
                        <div class = "successstyle nosuccesss" v-else-if="v.ExtensionState==2">已结束</div>
                        <div class = "successstyle nosuccesss" v-else-if="v.ExtensionState==3">已停用</div>
                        <div class = "successstyle errorbtn" v-else-if="v.ExtensionState==4">群聊异常</div>
                      </div>
                      <div class = "flowhidden">
                        <div class = "font-vertical2" style="width:100%">
                          {{v.GroupName}}
                        </div>
                      </div>
                      <div class = "flowhidden">
                        <div class = "bottom-redFont">当前群人数{{v.DetailCount}}人</div>
                      </div>
                    </div>
                  </div>
                  <div style="flex:1">
                    <div class = "groupcontent-top-center">
                      <div style="text-align:right;flex:1;padding:0px 10px" v-if="(v.ExtensionState==0||v.ExtensionState==1) && !noedit">
                        <el-button class = "drap" type="text">拖拽排序</el-button>
                        <el-button type="text" style="color:#f56c6c" @click="removegroup(i)" v-if="v.ExtensionState!=1 && !noedit">删除</el-button>
                        <el-button type="text" style="color:#f56c6c" v-if="v.ExtensionState==1 && !noedit" @click="stoppromote(i)">停止推广</el-button>
                      </div>
                      <div class = "groupcontent-garyfont" v-else>
                        <p v-if="v.ExtensionState==2">群聊已满员，自动结束推广</p>
                        <p v-else-if="v.ExtensionState==3">已停止推广</p>
                        <p v-else-if="v.ExtensionState==4">客户群已解散，本活动不再推送该群聊</p>
                        <el-button type="text" style="color:#f56c6c" @click="removegroup(i)" v-if="v.ExtensionState!=1 && !noedit">删除</el-button>
                      </div>
                    </div>
                  </div>
              </div>
              <div v-if="!ruForm.IsFinish&&activestate!=2&&ruForm.JoinGroupList.length<10" class = "groupcontentbox pushgroup-center" @click="openchoosestaff">
                <i class = "el-icon-plus" style="font-size:20px;margin-right:5px"></i>添加群聊
              </div>
            </draggable>
          </el-form-item>
        </el-card>
        <el-card style="margin-top:10px">
          <div class = "headFont">活动推送设置</div>
          <div class = "blueBox">
            <div class = "blueBoxFont" style="width:80px;text-align:right">
              <p>提示：</p>
            </div>
            <div class = "blueBoxFont">
              <p>1.若参与活动的推广群聊，在[进群欢迎语]里也被设置了欢迎语，活动期间客户加入客户群聊后，仅推送此处设置的内容和链接</p>
              <p>2.推送链接是客户生成专属海报的链接，请配置链接标题、链接描述和链接封面图</p>
              <p>3.活动开始后，本条推送内容和链接也会被当做群发消息内容，用于群主群发到客户群中， <span class = "redFont">同时会占用1次「企业群发」机会，每个客户每月只可收到4次企业群发</span></p>
              <p>4.根据 <a href="https://weixin.qq.com/cgi-bin/readtemplate?t=weixin_external_links_content_management_specification" class = "blueFont" target="_blank">
              《微信外部链接内容管理规范》</a>，请千万不要在文案或图片内出现诱导分享内容</p>
            </div>
          </div>
          <div class = "flexRow" style="margin-top:20px">
            <div>
              <el-form-item label="推送内容：" prop="Welcome">
                <!-- <el-button :disabled="noedit" type="text" @click="pushWXnick('inputDiv')">插入客户微信昵称</el-button> -->
                <!-- <el-button :disabled="noedit" type="text" @click="pushlink('inputDiv')">插入任务进度链接</el-button>
                <br/> -->
                <el-input v-model="ruForm.Welcome" :disabled="noedit" type="textarea"  style="width:800px"
                 :rows="10" maxlength="1000" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="推送链接：" class ="ladder">
                <el-form-item label="链接名称：" class = "nostar" label-width="100px" prop="LinkTitle">
                  <el-input :disabled="noedit" v-model="ruForm.LinkTitle" placeholder="请输入链接名称，最多30个字" maxlength="30" style="width:400px"></el-input>
                </el-form-item>
                <el-form-item label="链接描述：" class = "nostar" label-width="100px" style="margin:20px 0px">
                  <el-input :disabled="noedit" v-model="ruForm.LinkDesc" placeholder="请输入链接描述，最多60个字" maxlength="60" style="width:400px"></el-input>
                </el-form-item>
                <el-form-item label="链接封面：" class = "nostar" label-width="100px">
                  <div class = "flexRow" style="width:200px" v-if="ruForm.LinkPicurl">
                    <img :src="imgUrl+ruForm.LinkPicurl" style="width:148px;height:148px" alt="">
                    <el-button  :disabled="noedit" type="text" style="margin:130px 0px 0px 15px;color:rgb(245,108,108);padding:0px" @click="ruForm.LinkPicurl = ''">删除</el-button>
                  </div>
                  <el-upload :disabled="noedit" v-else list-type="picture-card" :action="imgApi"  accept="image/*" :on-success="onSuccess" :before-upload="beforeUpload"
                  :show-file-list="false">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div style="font-size:12px;color:#909399">图片大小请控制在1M内</div>
                </el-form-item>
              </el-form-item>
            </div>
            <div style="margin-left:20px;width:200px">
              <p style="color:#909399;font-size:14px">图片示例:</p>
              <img :src="imgUrl+'/image/friendsfission.png'" style="width:300px;height:650px" alt=""/>
            </div>
          </div>
          
        </el-card>
        <el-card style="margin-top:10px">
          <div class = "headFont">任务阶梯</div>
          <div class="flexCol flexcontent" style="margin:50px auto" v-if="bothNoAuth">
            <img src="../../../assets/img/20210805154814.png" alt="">
            <div style="font-size:14px;color:#606266;margin:20px 0px">商城还未绑定小程序，请先绑定</div>
            <el-button type="text" style="margin-top:-20px" @click="$router.push({path:'/setting/wxAppManage'})">去绑定</el-button>
          </div>
          <div v-else>
            <el-form-item label = "任务阶梯："  class = "ladder TaskLadder" style = "margin-top:20px">
              <div class = "grayFont">
                最少添加1级阶梯；最多添加5级阶梯。完成阶梯任务，即可领取对应阶梯奖励：客户参加活动，完成一级阶梯的任务，可领取一级阶梯奖励，后来客户继续分享，完成二级阶梯的任务，即可领取二级阶梯奖励，以此类推
              </div>
              <div>
                <el-form-item :ref="'ladderlist'+i" :key="v.random" v-for="(v,i) in ruForm.TaskConfigList"  style="margin:20px 0px">
                  <div class ="garybgc">
                    <div>{{numtostr(i)}}级阶梯</div>
                    <!-- {{activestate}} -->
                    <el-button  :disabled="noedit" type="text" style = "color:rgb(245,108,108)" @click="removeladder(i,v)"
                    v-if="ruForm.TaskConfigList.length>1&&activestate!=2&&(canTargetMemberCount[i].candel||activestate!=1)">删除</el-button>
                  </div>
                  <el-form-item label="目标人数：" :key="'TargetMemberCount'+i" :ref="'TargetMemberCount'+i" :prop="'TaskConfigList.'+i+'.TargetMemberCount'" :rules="rules.TargetMemberCount" style="margin-top:20px">
                    <el-input-number :key="'TargetMemberCount'+i" :disabled="canTargetMemberCount[i].canedit" v-model.trim="v.TargetMemberCount"  :controls="false"
                    style="width:150px;margin:0px 10px" placeholder="请填写目标人数"></el-input-number>人
                  </el-form-item>
                  <el-form-item label="任务奖励：" :prop="'TaskConfigList.'+i+'.PrizeType'" :rules="rules.PrizeType" style="margin-top:20px">
                    <el-radio-group :disabled="noedit" v-model="v.PrizeType" class = "flexCol">
                      <el-radio :label="1" :disabled = '!IsOpenMallTopUp' style="margin:0px 0px 10px 0px;width:300px">
                        <div style="display:inline-block">
                          <el-form-item  :ref="'GiftBalance'+i" :key="'GiftBalance'+i" :prop="'TaskConfigList.'+i+'.GiftBalance'" :rules="v.PrizeType==1?rules.GiftBalance:[{required:false}]" label-width="0px"
                          :class="{formremaining:!IsOpenMallTopUp,formremaining2:IsOpenMallTopUp}">
                            余额赠送<el-input-number :key="'GiftBalance'+i" :disabled="v.PrizeType!=1||noedit||!IsOpenMallTopUp" v-model.trim="v.GiftBalance"  :controls="false"
                            style="width:200px;margin:0px 10px" @blur="changeundefined('GiftBalance',i)"></el-input-number>元
                          </el-form-item>
                        </div>
                      </el-radio>
                        <div class="grayFont" v-if="!IsOpenMallTopUp" style="margin:0px 0px 0px 25px;height:30px">商城还未开启余额充值功能，请先前往开启 
                          <el-button type="text" style="margin:0px;padding:0px;font-size:12px" @click="$router.push({path:'/Customer/storedrule'})">去开启</el-button>
                        </div>
                      <el-radio :label="2" style="margin:10px 0px;width:300px">
                        <div style="display:inline-block">
                          <el-form-item  :ref="'Point'+i" :key="'Point'+i" :prop="'TaskConfigList.'+i+'.Point'" :rules="v.PrizeType==2?rules.Point:[{required:false}]" label-width="0px"
                          class = "formintegral">
                            送<el-input-number :key="'Point'+i" :disabled="v.PrizeType!=2||noedit" v-model.trim="v.Point" :controls="false"
                            style="width:200px;margin:0px 10px" ></el-input-number>积分
                          </el-form-item>
                        </div>
                      </el-radio>
                      <div style="margin:10px 0px;">
                        <el-radio :label="3">
                          送优惠券<el-button type="text" style="margin-left:15px" v-if="v.PrizeType==3&&!noedit" @click="choosecoupons(i)">选择优惠券</el-button>
                        </el-radio>
                          <el-form-item class = "laddercoupons" :prop="'TaskConfigList.'+i+'.ActivityCouponList'" :rules="v.PrizeType==3?rules.ActivityCouponList:[{required:false}]">
                            <div class="radioFont">若优惠券剩余数量小于赠送数量，完成任务的客户将领奖失败。请关注优惠券剩余数量并及时补充。该活动赠送的优惠券不受领取次数限制</div>
                          </el-form-item>
                          <div style="margin:30px 0px 0px 30px" v-show="v.PrizeType==3">
                            <el-table :data="v.ActivityCouponList"  :key="'ActivityCouponList'+i">
                              <el-table-column prop="CouponName" label="优惠券" width="120px">
                                <template slot-scope="scope">
                                  <div class = "vertical2">{{scope.row.CouponName}}</div>
                                  <div class = "errtips" v-if="scope.row.IsFailure">已失效</div>
                                  <div class = "errtips" v-else-if="scope.row.LeaveCount<=0">数量=0</div>
                                </template>
                              </el-table-column>
                              <el-table-column prop="UseTypeValue" label="类型" width="120px">
                                <template slot-scope="scope">
                                  <div class = "vertical2">{{scope.row.UseTypeValue}}</div>
                                </template>
                              </el-table-column>
                              <el-table-column prop="CouponContent" label="优惠内容">
                              </el-table-column>
                              <el-table-column prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
                              <el-table-column prop="" label="赠送数量/人">
                                <template slot-scope="scope">
                                  <div style="padding:0px 5px 15px 5px">
                                    <el-form-item :ref="'tableruf0'+i" :key="'SendActivityCouponCount'+i+scope.$index" :prop="'TaskConfigList.'+i+'.ActivityCouponList.'+scope.$index+'.SendActivityCouponCount'" 
                                    :rules="v.PrizeType==3?rules.SendActivityCouponCount:rules.nocheck">
                                      <el-input-number :key="'SendActivityCouponCount'+i+scope.$index"  @input="CouponCount($event,scope.row,i)" :disabled="noedit" v-model="scope.row.SendActivityCouponCount" 
                                      :min="1" :max="99" :controls="false" style="width:100px" ></el-input-number>
                                    </el-form-item>
                                  </div>
                                </template>
                              </el-table-column>
                              <el-table-column prop="" label="操作">
                                <template slot-scope="scope">
                                  <el-button v-if="!noedit" :disabled="noedit" type="text" style="color:#F56C6C" @click="removeActivityCouponList(i,scope.row)">删除</el-button>
                                </template>
                              </el-table-column>
                            </el-table>
                            
                            <div style="margin-top:10px;text-align:right" v-if="allCoupon[i].totalCoupon">
                              <el-pagination
                                @size-change="handleSizeChangeCoupon($event,i)"
                                @current-change="handleCurrentChangeCoupon($event,i)"
                                :current-page="allCoupon[i].currentPageCoupon"
                                :page-sizes="[5, 10, 20, 30]"
                                :page-size="allCoupon[i].sizepageCoupon"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="allCoupon[i].totalCoupon">
                              </el-pagination>
                            </div>
                            <!-- <couponstable :tablelist="v.ActivityCouponList" :ref="'couponseltable'+i"></couponstable> -->
                          </div>
                      </div>
                      <div style="margin:10px 0px;">
                          <el-radio :label="4">
                            送赠品<el-button type="text" style="margin-left:15px" v-if="v.PrizeType==4&&!noedit" @click="choosegift(i)">选择商品</el-button>
                          </el-radio>
                            <el-form-item class = "laddercoupons" :prop="'TaskConfigList.'+i+'.GiftProductList'" :rules="v.PrizeType==4?rules.GiftProductList:[{required:false}]">
                              <div class="radioFont">若商品库存小于赠送数量，完成任务的客户将领奖失败。请关注商品库存并及时补充</div>
                            </el-form-item>
                            <div style="margin:30px 0px 0px 30px" v-show="v.PrizeType==4">
                              <!-- <gitfstable :tablelist="v.GiftProductList" :ref="'gitfstable'+i"></gitfstable> -->
                              <el-table :data="v.GiftProductList"  :key="'GiftProductList'+i">
                                <el-table-column prop="Name" label="商品" width="170px">
                                  <template slot-scope="scope">
                                    <div class = "flexRow rowbetween">
                                      <img :src="imgUrl+scope.row.ImgUrl" style="width:40px;height:40px" alt="">
                                      <div>
                                        <div class = "vertical2" style="width:120px;margin-left:5px">
                                          {{scope.row.Name}}
                                        </div>
                                        <p class = "redmsg" >
                                          <span v-if="!scope.row.IsOpen">仓库中</span>
                                          <span v-else-if="scope.row.Stock==0">已售罄</span>
                                        </p>
                                        <!-- <span style="color: #E51C23;font-size: 12px;margin-left:5px">{{scope.row.Remark}}</span> -->
                                      </div>
                                    </div>
                                  </template>
                                </el-table-column>
                                <el-table-column prop="Spce" label="规格" width="100px">
                                  <template slot-scope="scope">
                                    <div class = "vertical2">
                                      {{scope.row.Spce?scope.row.Spce:'默认规格'}}
                                    </div>
                                  </template>
                                </el-table-column>
                                <el-table-column prop="Price" label="价格">
                                  <template slot-scope="scope">
                                    <div>
                                      ￥{{scope.row.Price}}
                                    </div>
                                  </template>
                                </el-table-column>
                                <el-table-column prop="Stock" label="库存"></el-table-column>
                                <el-table-column prop="" label="赠送数量/人" :key="2">
                                  <template slot-scope="scope">
                                    <div style="padding:0px 5px 15px 5px">
                                      <el-form-item :ref="'tableruf1'+i" :key="'SendGiftProductCount'+i+scope.$index" :prop="'TaskConfigList.'+i+'.GiftProductList.'+scope.$index+'.SendGiftProductCount'"
                                      :rules="v.PrizeType==4?rules.SendGiftProductCount:rules.nocheck">
                                        <el-input-number :key="'SendGiftProductCount'+i+scope.$index" :disabled="noedit" @input="ProductCount($event,scope.row,i,)" v-model="scope.row.SendGiftProductCount"
                                        :min="1" :max="99" :controls="false" style="width:100px"></el-input-number>
                                      </el-form-item>
                                    </div>
                                  </template>
                                </el-table-column>
                                <el-table-column prop="" label="操作">
                                  <template slot-scope="scope">
                                    <el-button v-if="!noedit" :disabled="noedit" type="text" style="color:#F56C6C" @click="removeGiftProduct(i,scope.row)">删除</el-button>
                                  </template>
                                </el-table-column>
                              </el-table>
                              <div style="margin-top:10px;text-align:right" v-if="allgift[i].totalGift">
                                <el-pagination 
                                  @size-change="handleSizeChangeGift($event,i)"
                                  @current-change="handleCurrentChangeGift($event,i)"
                                  :current-page="allgift[i].currentPageGift"
                                  :page-sizes="[5, 10, 15, 20]"
                                  :page-size="allgift[i].sizepageGift"
                                  layout="total, sizes, prev, pager, next, jumper"
                                  :total="allgift[i].totalGift">
                                </el-pagination>
                              </div>
                            </div>
                      </div>
                      
                    </el-radio-group>
                  </el-form-item>
                </el-form-item>
              </div>
              <div class ="garybgc" v-if="activestate!=2&&ruForm.TaskConfigList.length<5">
                <el-button  :disabled="noedit" type="text" @click="pushladder">添加{{numtostr(ruForm.TaskConfigList.length)}}级阶梯</el-button>
              </div>
            </el-form-item>
          </div>
        </el-card>
        <el-card style="margin-top:10px">
          <div class = "headFont">更多配置</div>
          <el-form-item label="朋友圈分享文案：" style="margin-top:20px">
            <el-input :disabled="noedit" type="textarea" v-model="ruForm.ShareContent" :rows="6" maxlength="200" :show-word-limit="true" style="width:600px"
             placeholder="请输入朋友圈分享文案，用于客户分享朋友圈时可以直接复制"></el-input>
             <el-button type="text" style="margin:20px 0px 0px 20px;padding:0px" @click="seeimgshow = true">查看示例</el-button>
          </el-form-item>
          <el-form-item label="分享海报：" prop="SharePoster">
            <div class = "flexRow">
              <img :src="imgUrl+ruForm.SharePoster" style="width:200px;height:350px" alt="">
              <div class = "flexCol colbetween">
                <div class = "postersFont">
                  <p>1.图片尺寸建议：宽750px，高1334px</p>
                  <p>2.图片大小2M以内</p>
                  <p>3.设计海报时，请预留位置：（1）左上角显示的客户微信头像和昵称；（2）右下角进群邀请二维码</p>
                  <p>4.根据 <a href="https://weixin.qq.com/cgi-bin/readtemplate?t=weixin_external_links_content_management_specification"
                   style="color:#409eff" target="_blank">《微信外部链接内容管理规范》</a> ，请千万不要在文案或图片内出现诱导分享内容</p>
                </div>
                <el-upload :disabled="noedit" list-type="text" style="color:#409eff;margin-left:15px" :action="imgApi"  accept="image/*" :show-file-list="false"
                 :on-success="onSuccess2" :before-upload="beforeUpload2">上传海报图</el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="奖励领取截止时间：" prop="AwardReceivingEndTime">
            <el-radio :disabled="noedit" v-model="ruForm.AwardReceivingEndTime" :label="1">和活动结束时间一致</el-radio>
          </el-form-item>
          <el-form-item label="仅新成员可助力：">
            <el-switch :disabled="noedit" v-model="ruForm.IsNewCustomerCanHelp" active-color="#13ce66"></el-switch>
            <div class = "radioFont" style="margin-left:0px">开启后，客户已经通过好友邀请加入本次活动的任一推广群聊，再次进入其他推广群聊，该客户不计入裂变成功</div>
          </el-form-item>
          <el-form-item label="客户标签：">
            <el-tag v-for="(v,i) in ruForm.TagDetailList" :key="i" type="info" effect="plain" style="margin:0px 10px 5px 0px"
             closable @close="noedit?'':handleClose(i)">{{v.Name}}</el-tag>
            <el-button :disabled="noedit" type="text" @click="dialogTag=true">选择标签</el-button>
            <div class = "radioFont" style="margin:5px 0px 0px 0px">给通过该裂变活动加入推广群聊的客户打上的标签</div>
          </el-form-item>
        </el-card>
      </el-form>
    </div>
    <el-card class="bottomCard" style="text-align:center;z-index:999">
      <el-button class = "bottomBtn" :class="{isend:activestate==2}" @click="$router.go(-1)">{{activestate==2?'返回':'取消'}}</el-button>
      <el-button v-if="activestate!=2" :disabled="noedit" class = "bottomBtn" type="primary" @click="save('ruForm')">保存</el-button>
    </el-card>
    <!-- 查看示例 -->
    <el-dialog title="查看示例" :visible.sync="seeimgshow" width="400px">
      <img :src="imgUrl+'/image/SharePoster.png'" style="width:100%" alt="">
    </el-dialog>
    <!-- 选择群聊 -->
    <el-dialog title="添加群聊" :visible.sync="choosestaffshow" width="70%"  @close="closestaff"  custom-class="staffpadding">
      <div class = "flexRow" style="flex-wrap:wrap">
        <div class=  "flexRow">
          <div class = "conditionsFont">关键字：</div>
          <el-input style="width:200px" v-model="staffkeywords" placeholder="群聊名称"></el-input>
        </div>
        <el-button type="primary" @click="querystaff" style="margin-left:10px">查询</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="stafftablelist" v-loading="stafftableloading" ref="stafftable"  :row-key="rowKeys"
         @selection-change = "selectionChange" max-height="500px">
          <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
          <el-table-column prop="GroupName" label="群名称" width="250px">
            <template slot-scope="scope">
              <div class = "flexRow">
                <img :src="groupImg" style="width:60px;height:60px;border-radius:3px" alt="">
                <div class = "flexRow" style="align-items:center;">
                  <div class = "vertical2 groupName">{{scope.row.GroupName}}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="EmployeeName" label="群主" width="200px">
            <template slot-scope="scope">
              <el-tag >
                <i class = "el-icon-user">{{scope.row.EmployeeName}}</i>
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="建群时间"></el-table-column>
          <el-table-column prop="DetailCount" label="群内人数"></el-table-column>
        </el-table>
      </div>
      <div style="margin-top:10px" class="flexRow colbetween">
        <el-checkbox :value="staffallcheck" @change="allcheckchange" style="margin-left:15px">当前页全选</el-checkbox>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="sizepage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div style="text-align:center">
        已选中 <span style="color:rgb(245,108,108)">{{staffallchecklist.length}}</span> 个
        <el-button type="primary" style="width:90px;height:36px;margin-left:10px" @click="staffcomfire">确认</el-button>
      </div>
    </el-dialog>
    <!-- 选择优惠券 -->
    <!-- {{laddernum}} -->
    <couponsdialog :visible.sync="couponsdialogshow" @confirm="getcouponslist" @close="couponsdialogshow = false"
     :thelist="allCoupon[laddernum].allCouponlist"></couponsdialog>
    <!-- 选择商品 -->
    <giftdialog :visible.sync="giftdialogshow" @confirm="getgiftlist" @close="giftdialogshow = false"
     :thelist="allgift[laddernum].allgiftlist"></giftdialog>
    <!-- 打标签 -->
    <tagging :dialogTag="dialogTag" :selected="ruForm.TagDetailList" @close="dialogTag=false" @confirm="confirmtag" :tagkey="'Id'"
    :title="'选择标签'"></tagging>
  </div>
</template>

<script>
import {
		mapGetters
	} from 'vuex'
import config from "@/config"
import apiList from '@/api/other';
import tagging from "../components/tagging"
import couponsdialog from "../components/couponsdialog"
import giftdialog from "../components/giftdialog"
import draggable from 'vuedraggable';
import {
  qyweixinqyweixingroupfissionactivitychosegrouplist,
  qyweixinqyweixingroupfissionactivityedit,
  qyweixinqyweixingroupfissionactivitydetail,
} from "@/api/sv1.0.0"
import {
		topUpConfigIndex,
	} from '@/api/TurnTomySelf'
import {
  shopdroplist
} from "@/api/api"
export default {
  components:{
    tagging,
    couponsdialog,
    giftdialog,
    draggable
  },
  data () {
    var checkStartTime = (rule, value, callback) => {
      if(!value){
        callback(new Error('请完善开始时间'))
      }else if(value.getTime() < new Date().getTime()-1000*60*60*24){
        callback(new Error('开始日期不可选择昨天及以前的日期'))
      }else if(value && this.ruForm.EndTime && value > this.ruForm.EndTime){
        callback(new Error('开始日期不可大于结束日期'))
      }else{
        callback()
      }
    }
    var checkEndTime = (rule, value, callback) => {
      if(!value){
        callback(new Error('请完善结束时间'))
      }else if(value && this.ruForm.StartTime && value < this.ruForm.StartTime){
        callback(new Error('结束日期不可早于开始日期'))
      }else{
        callback()
      }
    }
    var checkWelcome = (rule, value, callback) => {
      if(!this.filterlabel(value)){
        callback(new Error('推送内容不可为空'))
        this.$message.error('推送内容不可为空')
      }else{
        callback()
      }
    }
    var TargetMemberCount = (rule, value, callback)=>{
      // console.log(value,parseInt(value))
      // console.log(value==parseInt(value))
      let str1 = 'TaskConfigList.'
      let str2 = '.TargetMemberCount'
      let num = Number(rule.field.substring(rule.field.indexOf(str1)+str1.length,rule.field.indexOf(str2)))
      // console.log(num)
      if(!value || value > 99 || value < 1 || value!=parseInt(value)){
        callback(new Error('人数请设置在1~99之间，整数'))
      }else if(num>0){
        if(value<=this.ruForm.TaskConfigList[num-1].TargetMemberCount){
          this.$message.error('下一阶梯目标人数需大于上一阶梯目标人数')
          callback(new Error('下一阶梯目标人数需大于上一阶梯目标人数'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    }
    var checkGiftBalance =  (rule, value, callback) => {
      let two = /^\d+(\.\d{1,2})?$/
      if(!this.IsOpenMallTopUp){
        callback(new Error('商城还未开启余额充值功能，请先前往开启'))
      }else if(value > 99 || value < 0.01){
        callback(new Error('余额请设置在0.01~99元之间'))
      }else if(!two.test(value)){
        callback(new Error('余额请设置在0.01~99元之间'))
      }else{
        callback()
      }
    }
    var checkPoint = (rule, value, callback) => {
      // console.log(value)
      if(!value || value > 9999 || value < 1 ||  value!=parseInt(value)){
        callback(new Error('积分请设置在1~9999之间'))
      }else{
        callback()
      }
    }
    var checkSendActivityCouponCount = (rule, value, callback) => {
      if(!value||value<1||value>99|| value!=parseInt(value)){
        callback(new Error('数量请设置在1~99之间，整数'))
      }else{
        callback()
      }
    }
    var checkSendGiftProductCount = (rule, value, callback) => {
      if(!value||value<1||value>99|| value!=parseInt(value)){
        callback(new Error('数量请设置在1~99之间，整数'))
      }else{
        callback()
      }
    }
    var checkQrCode = (rule, value, callback) => {
      // let str1 = 'JoinGroupList.'
      // let str2 = '.QrCode'
      // let num = rule.field.substring(rule.field.indexOf(str1)+str1.length,rule.field.indexOf(str2))
      // let val = this.ruForm.JoinGroupList[num].QrCode
      if(!value){
        callback(new Error('请上传群聊二维码'))
      }else{
        callback()
      }
    }
    var checkPrizeType = (rule, value, callback) => {
      // console.log(rule)
      let str1 = 'TaskConfigList.'
      let str2 = '.PrizeType'
      let num = Number(rule.field.substring(rule.field.indexOf(str1)+str1.length,rule.field.indexOf(str2)))
      if(!value){
        callback(new Error('请至少选择1种奖励'))
      }else{
        callback()
      }
    }
    var checkActivityCouponList = (rule, value, callback) => {
      if(value&&value.length){
        callback()
      }else{
        callback(new Error('请选择赠送的优惠券'))
      }
    }
    var checkGiftProductList = (rule, value, callback) => {
      if(value&&value.length){
        callback()
      }else{
        callback(new Error('请选择赠品'))
      }
    }
    return {
      pageloading:false,
      IsOpenMallTopUp:false,
      noedit:false,
      groupImg: 'https://cdn.dkycn.cn/images/melyshop/grouplist.png',
      activestate:0,
      prolist:[],
      imgApi: config.UPLOAD_IMG,
      imgUrl: config.IMG_BASE,
      ruForm:{
        Id:0,
        IsFinish:false,
        ActivityName:'',
        StartTime:'',
        EndTime:'',
        JoinGroupList:[],
        Welcome:'一大波奖品派送中！\n奖品获得流程：\n①点击下面链接，生成你的专属海报\n②长按保存海报，将海报发给【微信好友】或发到【微信朋友圈】\n③邀请【x位】好友扫码加入群聊后，即可获得奖品哦\n\n注意事项：加入后又退出群聊的助力，会失效哦~',
        AttachmentInfoList:[{
          Type:2,
          ImgUrl:'',
          ImgUrlComplete:'',
          LinkUrl:'',
          LinkName:'',
          Description:'',
        }],
        LinkTitle:'',
        LinkDesc:'',
        LinkPicurl:'',
        TaskConfigList:[{
          Id:0,
          TargetMemberCount:undefined,
          PrizeType:0,
          Level:0,
          GiftBalance:undefined,
          Point:undefined,
          ActivityCouponList:[
            // {SendActivityCouponCount:1}
          ],
          GiftProductList:[
            // {SendGiftProductCount:1}
          ]
        }],
        ShareContent:'',
        SharePoster:'/image/reproduction.png',
        AwardReceivingEndTime:1,
        IsNewCustomerCanHelp:false,
        TagDetailList:[],
      },
      rules:{
        ActivityName:[
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        StartTime:[
          // { required: true, message: '请完善开始时间', trigger: 'blur' },
          { required: true, validator: checkStartTime, trigger: 'blur' },
        ],
        EndTime:[
          // { required: true, message: '请完善结束时间', trigger: 'blur' },
          { required: true, validator: checkEndTime, trigger: 'blur' },
        ],
        JoinGroupList:[
          { required: true, message: '请选择推广群聊', trigger: 'blur' },
        ],
        Welcome:[
          { required: true,validator:checkWelcome, trigger: 'blur' },
        ],
        LinkTitle:[
          { required: true, message: '请输入链接名称', trigger: 'blur' },
        ],
        TargetMemberCount:[
          // { required: true, message: '请设置本阶梯目标人数', trigger: 'blur' },
          { required: true, validator: TargetMemberCount, trigger: 'blur' },
        ],
        PrizeType:[
          { required: true, validator: checkPrizeType, trigger: 'blur' },
        ],
        GiftBalance:[
           { required: true, message: '余额请设置在0.01~99元之间', trigger: 'blur' },
           { required: true, validator: checkGiftBalance, trigger: 'blur' },
        ],
        Point:[
          { required: true, message: '积分请设置在1~9999之间', trigger: 'blur' },
          { required: true, validator: checkPoint, trigger: 'blur' },
        ],
        ActivityCouponList:[
          { required: true, message: '请选择赠送的优惠券', trigger: 'blur' },
          { required: true,validator: checkActivityCouponList, trigger: 'blur' },
        ],
        GiftProductList:[
          { required: true, message: '请选择赠品', trigger: 'blur' },
          { required: true,validator: checkGiftProductList, trigger: 'blur' },
        ],
        SharePoster:[
           { required: true, message: '请上传海报图', trigger: 'blur' },
        ],
        AwardReceivingEndTime:[
          { required: true, message: '请选择奖励领取截止时间', trigger: 'blur' },
        ],
        SendActivityCouponCount:[
          { required: true, message: '数量请设置在1~99之间，整数', trigger: 'blur' },
          { required: true, validator: checkSendActivityCouponCount, trigger: 'blur' },
        ],
        SendGiftProductCount:[
          { required: true, message: '数量请设置在1~99之间，整数', trigger: 'blur' },
          { required: true, validator: checkSendGiftProductCount, trigger: 'blur' },
        ],
        QrCode:[
          // { required: true, message: '请上传群聊二维码', trigger: 'blur' },
          { required: true, validator: checkQrCode, trigger: 'blur' },
        ],
        nocheck:[
          { required: false},
        ]
      },
      pickerOptions:{
        disabledDate:(v)=>{
          return v.getTime() < new Date().getTime()-1000*60*60*24
        }
      },
      pickerOptions2:{
        disabledDate:(v)=>{
          if(this.ruForm.StartTime){
            return v.getTime() < new Date().getTime()-1000*60*60*24 || v.getTime() <= this.ruForm.StartTime.getTime()-1000*60*60*24
          }else{
            return v.getTime() < new Date().getTime()-1000*60*60*24
          }
        }
      },
      divfouse:false,
      placeholdershow:true,
      dialogTag:false,
      choosestaffshow:false,
      staffkeywords:'',
      stores:'',
      storeslist:[],
      stafftablelist:[],//展示的员工
      allstafftablelist:[],//所有的员工

      stafftableloading:false,
      // staffallcheck:false,
      staffallchecklist:[],
      currentPage:1,
      sizepage:10,
      total:null,
      canTargetMemberCount:[//目标人数是否被禁用   是否可被删除
        {
          canedit:false,
          candel:false,
        }
      ],
      allCoupon:[
        {
          allCouponlist:[],
          currentPageCoupon:1,
          sizepageCoupon:5,
          totalCoupon:null,
        }
      ],
      
      allgift:[
        {
          allgiftlist:[],
          currentPageGift:1,
          sizepageGift:5,
          totalGift:null,
        }
      ],

      couponsdialogshow:false,
      laddernum:0,
      giftdialogshow:false,

      seeimgshow:false,

      isvalidation:false,//获取优惠券和赠品时会验证表单  如果是第一次验证则不会验证
    }
  },
  computed:{
    ...mapGetters([
				'mallInfo'
			]),
    //选择员工 当前页全选
    staffallcheck(){
      // console.log(this.stafftablelist)
      if(this.stafftablelist&&this.stafftablelist.length){
        return this.stafftablelist.every((v)=>{
          return this.staffallchecklist.some((x)=>{
            return x.Id==v.Id
          })
        })
      }else{
        return false
      }
    },
    bothNoAuth(){
				if(this.prolist&&this.prolist.length){
          let a = this.prolist.find((v)=>{
            return v.MicroProgramType==0
          })
          if(!a){
            return true
          }else{
            return false
          }
        }else{
          return true
        }
		}
  },
  created () {
    this.getrandom()
    // console.log(this.mallInfo)
    if(Number(this.$route.query.Id) != 0){
      this.getmsg()
    }
    this.getprolist()
    this.gettopUpConfigIndex()
  },
  methods: {
    getrandom(){
      return Math.random()
    },
    gettoday(){
      let date = new Date()
      return date
    },
    changedata(time,isend){
      if(time){
        let year = time.getFullYear()
        let mon = (time.getMonth()+1)>=10?time.getMonth()+1:'0'+(time.getMonth()+1)
        let day = time.getDate()>=10?time.getDate():'0'+time.getDate()
        let hours = time.getHours()>=10?time.getHours():'0'+time.getHours()
        let min = time.getMinutes()>=10?time.getMinutes():'0'+time.getMinutes()
        let sec = time.getSeconds()>=10?time.getSeconds():'0'+time.getSeconds()
        // if(isend){
        //   return year+'-'+mon+'-'+day+' '+"23:59:59"
        // }else{
          // console.log(mon)
          return year+'-'+mon+'-'+day+' '+hours+':'+min+':'+sec
        // }
      }
    },
    numtostr(e){
      let str = ['一','二','三','四','五','六','七']
      return str[e]
    },
    filterlabel(e){
      let str  = JSON.parse(JSON.stringify(e))
      let newe = str.replace(/<[^<>]+>/g,'')
      return newe
    },
    getscopeindex(e,m,num){
      console.log(e)
      let arr = []
      let key = ''
      if(num==0){
        key = 'ActivityCouponId'
        arr = this.allCoupon[m].allCouponlist
      }else{
        key = 'GiftProductSpecId'
        arr = this.allgift[m].allgiftlist
      }
      // return i
      // return arr.
      let a = ''
      arr.forEach((v,i)=>{
        if(v[key] == e[key]){
          a = i
        }
      })
      // console.log(a)
      return a
    },
    CouponCount(e,msg,i){
      this.allCoupon[i].allCouponlist = this.allCoupon[i].allCouponlist.map((v)=>{
        if(v.ActivityCouponId == msg.ActivityCouponId){
          v.SendActivityCouponCount = e
        }
        return v
      })
      this.ruForm.TaskConfigList[i].ActivityCouponList = this.ruForm.TaskConfigList[i].ActivityCouponList.map((v)=>{
        if(v.ActivityCouponId == msg.ActivityCouponId){
          v.SendActivityCouponCount = e
        }
        return v
      })
    },
    ProductCount(e,msg,i){
      // console.log(msg)
      this.allgift[i].allgiftlist = this.allgift[i].allgiftlist.map((v)=>{
        if(v.GiftProductSpecId == msg.GiftProductSpecId){
          v.SendGiftProductCount = e
        }
        return v
      })
      this.ruForm.TaskConfigList[i].GiftProductList = this.ruForm.TaskConfigList[i].GiftProductList.map((v)=>{
        if(v.GiftProductSpecId == msg.GiftProductSpecId){
          v.SendGiftProductCount = e
        }
        return v
      })
    },
    changetime1(e){
      // this.changedata(e)
      // if(e.getDate<this.gettoday){
      //   e = this.gettoday()
      // }
    },
    changetime2(){

    },
    closestaff(){
      this.choosestaffshow = false
      this.staffkeywords = ''
      this.stores = null
      this.currentPage = 1
      this.sizepage = 10
      this.staffmay = false
      this.$refs.stafftable.clearSelection()
    },

    handleAvatarSuccess(file,i){
      // console.log(file[0],i)
      this.ruForm.JoinGroupList[i].QrCode = file[0]
      let obj = JSON.parse(JSON.stringify(this.ruForm.JoinGroupList[i]))
      obj.QrCode = file[0]
      this.ruForm.JoinGroupList.splice(i,1,obj)
      this.changestate()
    },
    beforeAvatarUpload(file,i){
      // console.log(file)
        const size = file.size / 1024 / 1024 < 1;
				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!size) {
					this.$message.error('图片大小请控制在2M以内');
					return false
				}
    },
    //目标人数 余额 积分 点击空白如果为0则清空  
    changeundefined(key,i){
      if(!this.ruForm.TaskConfigList[i][key]){
        this.ruForm.TaskConfigList[i][key] = undefined
      }
    },



    //看列表群聊中是否有推广中的群聊 如果没有则第一个未开始的群聊变成推广中
    changestate(){
      if(this.ruForm.JoinGroupList&&this.ruForm.JoinGroupList.length){
        let ishave = this.ruForm.JoinGroupList.every((v)=>{
          return v.ExtensionState != 1
        })
        if(ishave){
          let a = this.ruForm.JoinGroupList.find((x)=>{return x.ExtensionState==0 && x.QrCode})
          // console.log(a,1)
          this.ruForm.JoinGroupList = this.ruForm.JoinGroupList.map((v)=>{
            if(a && v.QyWeixinRealGroupId == a.QyWeixinRealGroupId){
              // console.log(v)
              v.ExtensionState = 1
            }
            return v
          })
        }
        // this.$forceUpdate()
      }
    },
    //停止推广
    stoppromote(e){
      this.$confirm('停止推广后，暂不支持重新开启，是否确认停止？','提示',{
        confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(()=>{
        this.ruForm.JoinGroupList[e].ExtensionState = 3
        // console.log(2)
        this.changestate()
        // console.log(5)
        // console.log(this.ruForm.JoinGroupList[e])
      }).catch(()=>{})
    },
    //删除群聊
    removegroup(e){
      // console.log(e)
      this.$confirm('是否删除该群聊?','提示',{
        confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(()=>{
        if(e.ExtensionState==1){
          this.$message.error('无法删除推广中的群聊')
        }else{
          this.ruForm.JoinGroupList.splice(e,1)
          this.changestate()
        }
      }).catch(()=>{})
    },

    handleSizeChangeCoupon(e,i){
      this.allCoupon[i].currentPageCoupon = 1
      this.allCoupon[i].sizepageCoupon = e
      this.filtercoupons(i)
    },
    handleCurrentChangeCoupon(e,i){
      this.allCoupon[i].currentPageCoupon = e
      this.$nextTick(()=>{
        this.$refs['tableruf0'+i].map((v)=>{
          v.clearValidate()
        })
      })
      this.filtercoupons(i)
    },
    handleSizeChangeGift(e,i){
      this.allgift[i].currentPageGift = 1
      this.allgift[i].sizepageGift = e
      this.filtergifts(i)
    },
    handleCurrentChangeGift(e,i){
      this.allgift[i].currentPageGift = e
      this.$nextTick(()=>{
        this.$refs['tableruf1'+i].map((v)=>{
          v.clearValidate()
        })
      })
      this.filtergifts(i)
    },
    //筛选数据  做分页
    filtercoupons(num){
      let arr = this.allCoupon[num].allCouponlist.filter((v,i)=>{
        return i>=(this.allCoupon[num].currentPageCoupon-1)*this.allCoupon[num].sizepageCoupon && i<this.allCoupon[num].currentPageCoupon*this.allCoupon[num].sizepageCoupon
      })
      if(arr&&arr.length){
        this.ruForm.TaskConfigList[num].ActivityCouponList = arr
      }else{
        if(arr.length<=0&&this.allCoupon[num].currentPageCoupon>1){
          this.allCoupon[num].currentPageCoupon--
          this.filtercoupons(num)
        }else{
          this.ruForm.TaskConfigList[num].ActivityCouponList = arr
        }
      }
    },
    filtergifts(num){
      let arr = this.allgift[num].allgiftlist.filter((v,i)=>{
        return i>=(this.allgift[num].currentPageGift-1)*this.allgift[num].sizepageGift && i<this.allgift[num].currentPageGift*this.allgift[num].sizepageGift
      })
      if(arr&&arr.length){
        this.ruForm.TaskConfigList[num].GiftProductList = arr
      }else{
        if(arr.length<=0&&this.allgift[num].currentPageGift>1){
          this.allgift[num].currentPageGift--
          this.filtergifts(num)
        }else{
          this.ruForm.TaskConfigList[num].GiftProductList = arr
        }
      }
    },
    //删除赠送商品
    removeGiftProduct(n,e){
      this.allgift[n].allgiftlist = this.allgift[n].allgiftlist.filter((v) => {
        return v.GiftProductSpecId!=e.GiftProductSpecId
      })
      this.allgift[n].totalGift = this.allgift[n].allgiftlist.length
      this.filtergifts(n)
      
    },
    //删除优惠券
    removeActivityCouponList(n,e){
      // console.log(n,this.allCoupon[n])
      this.allCoupon[n].allCouponlist = this.allCoupon[n].allCouponlist.filter((v) => {
        return v.ActivityCouponId!=e.ActivityCouponId
      })
      this.allCoupon[n].totalCoupon = this.allCoupon[n].allCouponlist.length
      this.filtercoupons(n)
    },
    //门店列表
    async getshopdroplist(){
      const res = await shopdroplist({
					IsOpen: true
				});
				if (res.IsSuccess) {
          // console.log(res.Result)
					this.storeslist = res.Result;
				}
    },  

    //获取选中的优惠券
    getcouponslist(e){
      if(e&&e.length){
        this.couponsdialogshow = false
        this.allCoupon[this.laddernum].allCouponlist = e.map((v)=>{
          v.ActivityCouponId = v.Id
          let a = this.allCoupon[this.laddernum].allCouponlist.find((x)=>{
            return x.ActivityCouponId == v.Id
          })
          v.SendActivityCouponCount = a?a.SendActivityCouponCount:1
          return v
        })
        
        this.allCoupon[this.laddernum].totalCoupon = e.length
        this.filtercoupons(this.laddernum)
        if(this.isvalidation){
          this.$refs['ruForm'].validate()
        }
      }else{
        this.$message('请选择优惠券')
      }
    },

    //获取选中的赠品
    getgiftlist(e){
      if(e&&e.length){
        this.giftdialogshow = false
        this.allgift[this.laddernum].allgiftlist = e.map((v)=>{
          // let a = {
          //   SendGiftProductCount:1
          // }
          // v.SendGiftProductCount = v.SendGiftProductCount?v.SendGiftProductCount:1
          // v = Object.assign(v,a);
          v.GiftProductId = v.ProductId
          v.GiftProductSpecId = v.ProductSpecId
          let a = this.allgift[this.laddernum].allgiftlist.find((x)=>{
            return x.GiftProductSpecId == v.ProductSpecId
          })
          v.SendGiftProductCount = a?a.SendGiftProductCount:1
          // console.log(a)
          return v
        })
        this.allgift[this.laddernum].totalGift = e.length
        this.filtergifts(this.laddernum)
        if(this.isvalidation){
          this.$refs['ruForm'].validate()
        }
      }else{
        this.$message('请选择商品')
      }
    },
    choosecoupons(e){
      this.laddernum = e
      this.couponsdialogshow = true
    },
    choosegift(e){
      this.laddernum = e
      this.giftdialogshow = true
    },
    //确定选择群聊
    staffcomfire(){
      // console.log(this.staffallchecklist)
      if(this.activestate==2||this.ruForm.IsFinish){
        this.$message.error('活动已结束，添加群聊失败')
      }else if(this.staffallchecklist.length+this.ruForm.JoinGroupList.length>10){
        this.$message.error('每场活动最多选择10个群聊')
      }else if(this.staffallchecklist.length<=0){
        this.$message.error('请选择推广群聊')
      }else{
        // this.ruForm.JoinGroupList = []
        this.staffallchecklist.map((v,i)=>{
          v.QrCode = ''
          v.ExtensionState = ''
          v.QyWeixinRealGroupId = v.Id
          v.Sort = i
          this.ruForm.JoinGroupList.push(v)
        })
        this.$forceUpdate()
        //id倒序排列
        this.ruForm.JoinGroupList.sort((a,b)=>{
          return b.QyWeixinRealGroupId-a.QyWeixinRealGroupId
        })
        this.changestate()
        // console.log(this.ruForm.JoinGroupList.map((v)=>{return v.QyWeixinRealGroupId}))
        this.choosestaffshow = false
        this.staffallchecklist = []
      }
    },
    rowKeys(row) {
      // console.log(row.Id)
				return row.Id
		},
    allcheckchange(e){
      this.$refs.stafftable.toggleAllSelection()
      if(e.length>10){
        this.$message.error('每场活动最多选择10个群聊')
      }
    },
    selectionChange(e){
      // console.log(e)
      this.staffallchecklist = e
      if(e.length>10){
        this.$message.error('每场活动最多选择10个群聊')
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.getstafftablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.getstafftablelist()
    },
    querystaff(){
      this.currentPage = 1
      this.getstafftablelist()
    },
    //获取群列表
    async getstafftablelist(){
      this.stafftableloading = true
      try{
        let data = {
          Id:this.$route.query.Id,
          Keywords:this.staffkeywords,
          // ShopId:this.stores
          StartTime:this.changedata(this.ruForm.StartTime),
          EndTime:this.changedata(this.ruForm.EndTime),
        }
        // console.log(this.changedata(this.ruForm.StartTime),this.changedata(this.ruForm.EndTime))
        let result = await qyweixinqyweixingroupfissionactivitychosegrouplist(data)
        if(result.IsSuccess){
          // console.log(result.Result)
          this.allstafftablelist = result.Result
          this.total = this.nocoincide(this.allstafftablelist,this.ruForm.JoinGroupList).length
          this.stafftablelist = this.nocoincide(this.allstafftablelist,this.ruForm.JoinGroupList).filter((v,i)=>{
            return i>=(this.currentPage-1)*this.sizepage && i<this.currentPage*this.sizepage
          })
        }
      }finally{
        this.stafftableloading = false
      }
    },
    //从arr1中剔除arr2
    nocoincide(arr1,arr2){
      return arr1.filter((v)=>{
          return !arr2.some((x)=>{
            return x.QyWeixinRealGroupId==v.Id
          })
        })
    },
    //选择群聊
    openchoosestaff(){
      if(this.ruForm.StartTime&&this.ruForm.EndTime){
        this.getstafftablelist()
        this.$nextTick(()=>{
          this.$refs.stafftable.clearSelection()
        })
        this.choosestaffshow = true
      }else{
        this.$message.error('请先选择活动时间')
      }
    },
    //删除标签
    handleClose(e){
      // console.log(e)
      this.ruForm.TagDetailList.splice(e,1)
    },
    //确认选择标签
    confirmtag(list){
      // console.log(list)
      this.ruForm.TagDetailList = list.map((v)=>{
        v.Id = v.TagDetailId
        return v
      })
      this.dialogTag=false
    },
    beforeUpload2(file){
      const size = file.size / 1024 / 1024 < 2;
      // console.log(file)
			const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg','image/webp']
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!size) {
					this.$message.error('分享海报图片大小请控制在2M以内');
					return false
				}
    },
    onSuccess2(file){
      this.ruForm.SharePoster = file[0]
    },
    //删除级别
    removeladder(e,v){
      // console.log(v)
      // let num
      // this.ruForm.TaskConfigList = this.ruForm.TaskConfigList.filter((x,y)=>{
      //   if(x.Id==v.Id){
      //     num=y
      //   }
      //   return x.Id!=v.Id
      // })
      this.laddernum = 0
      this.ruForm.TaskConfigList.splice(e,1)
      this.allCoupon.splice(e,1)
      this.allgift.splice(e,1)
    },
    pushladder(){
      this.ruForm.TaskConfigList.push({
        Id:0,
        TargetMemberCount:undefined,
        PrizeType:0,
        Level:0,
        GiftBalance:undefined,
        Point:undefined,
        ActivityCouponList:[],
        GiftProductList:[],
        random:this.getrandom()
      })
      this.canTargetMemberCount.push({
        canedit:false,
        candel:true,
      })
      this.allCoupon.push({
          allCouponlist:[],
          currentPageCoupon:1,
          sizepageCoupon:5,
          totalCoupon:null,
        })
      
      this.allgift.push({
          allgiftlist:[],
          currentPageGift:1,
          sizepageGift:5,
          totalGift:null,
        })
    },
    watchInput(e){
      if(e.target.innerHTML.length>0){
        this.placeholdershow = false
      }else{
        this.placeholdershow = true
      }
    },

    beforeUpload(file){
      const size = file.size / 1024 / 1024 < 1;
      // console.log(file)
			const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg','image/webp']
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!size) {
					this.$message.error('封面图大小请控制在1M以内');
					return false
				}
    },
    onSuccess(file){
      this.ruForm.LinkPicurl = file[0]
      this.$forceUpdate()
    },
    async tosave(){
      // console.log('保存')
      this.pageloading = true
      try{
        let data = {Info:JSON.parse(JSON.stringify(this.ruForm))}
        let StartTime = this.changedata(this.ruForm.StartTime)
        let EndTime = this.changedata(this.ruForm.EndTime)
        // console.log(StartTime,EndTime)
        data.Info.StartTime = StartTime
        data.Info.EndTime = EndTime
        // console.log(data.Info.JoinGroupList)
        data.Info.JoinGroupList = data.Info.JoinGroupList.map((v,i)=>{
          v.Sort = i + 1
          return v
        })
        let msg = {
          ImgUrl:data.Info.LinkPicurl?data.Info.LinkPicurl:'',
          LinkName:data.Info.LinkTitle?data.Info.LinkTitle:'',
          Description:data.Info.LinkDesc?data.Info.LinkDesc:'',
          Type:2,
        }
        if(data.Info.AttachmentInfoList&&data.Info.AttachmentInfoList.length){
          data.Info.AttachmentInfoList[0] = msg
        }else{
          data.Info.AttachmentInfoList.push(msg)
        }
        // console.log(data.Info.AttachmentInfoList)
        // data.Info.AttachmentInfoList[0].ImgUrl = data.Info.LinkPicurl
        // data.Info.AttachmentInfoList[0].LinkName = data.Info.LinkTitle
        // data.Info.AttachmentInfoList[0].Description = data.Info.LinkDesc
        data.Info.TaskConfigList.map((v,i)=>{
          v.Level = i+1
          if(this.allCoupon[i].allCouponlist&&this.allCoupon[i].allCouponlist.length){
            v.ActivityCouponList = this.allCoupon[i].allCouponlist
          }
          if(this.allgift[i].allgiftlist&&this.allgift[i].allgiftlist.length){
            v.GiftProductList = this.allgift[i].allgiftlist
          }
        })
        if(this.$route.query.iscopy){
          this.ruForm.Id = 0
        }
        
        let result = await qyweixinqyweixingroupfissionactivityedit(data)
        if(result.IsSuccess){
          this.$message.success('保存成功')
          this.$router.go(-1)
        }
      }finally{
        this.pageloading = false
      }
    },
    changeTypemsg(v){
      if(v.PrizeType==1){
        v.Point=undefined
        v.ActivityCouponList=[]
        v.GiftProductList=[]
      }else if(v.PrizeType==2){
        v.GiftBalance=undefined
        v.ActivityCouponList=[]
        v.GiftProductList=[]
      }else if(v.PrizeType==3){
        v.GiftBalance=undefined
        v.Point=undefined
        v.GiftProductList=[]
      }else if(v.PrizeType==4){
        v.GiftBalance=undefined
        v.Point=undefined
        v.ActivityCouponList=[]
      }
      return v
    },
    //保存
    save(formName) {
      this.$refs[formName].validate((valid) => {
        this.isvalidation = true
        // console.log(this.ruForm)
          if (valid && [this.validation(),this.validation2()].every((v)=>{return v})) {
            this.tosave()
          } else {
            if(!this.ruForm.JoinGroupList||!this.ruForm.JoinGroupList.length){
              this.$message.error('请选择推广群聊')
            }else if(!this.ruForm.Welcome){
              this.$message.error('推送内容不可为空')
            } if(![this.validation(),this.validation2()].every((v)=>{return v})){
              this.$message.error('请完善任务阶梯')
              this.$refs[formName].validate((valid) => {})
              this.$nextTick(()=>{
                let errdiv = document.getElementsByClassName('TaskLadder')
                errdiv[0].scrollIntoView({
                    block:'center',
                    behavior:'smooth'
                  })
                })
            }else{
              this.$message.error('请完善活动信息')
            }
            this.$nextTick(()=>{
              let iserror = document.getElementsByClassName('is-error')
              iserror[0].scrollIntoView({
                block:'center',
                behavior:'smooth'
              })
            })
            return false;
          }
        });
    },
    //验证优惠券 赠品是否填写完整
    validation(){
      return this.allCoupon.every((v,i)=>{
        // console.log(this.ruForm.TaskConfigList[i].PrizeType)
        if(this.ruForm.TaskConfigList[i].PrizeType!=3){
          return true
        }else{
          return v.allCouponlist.every((x,y)=>{
            if(!x.SendActivityCouponCount||x.SendActivityCouponCount<1||x.SendActivityCouponCount>99){
              if(y<=0){
                this.allCoupon[i].currentPageCoupon = 1
              }else{
                this.allCoupon[i].currentPageCoupon = Math.ceil(y/this.allCoupon[i].sizepageCoupon)
              }
              this.filtercoupons(i)
              return false
            }else{
              return true
            }
          })
        }
      })
    },
    validation2(){
      return this.allgift.every((v,i)=>{
        if(this.ruForm.TaskConfigList[i].PrizeType!=4){
          return true
        }else{
          return v.allgiftlist.every((x,y)=>{
            if(!x.SendGiftProductCount||x.SendGiftProductCount<1||x.SendGiftProductCount>99){
              if(y<=0){
                this.allgift[i].currentPageGift = 1
              }else{
                this.allgift[i].currentPageGift = Math.ceil(y/this.allgift[i].sizepageGift)
              }
              this.filtergifts(i)
              return false
            }else{
              return true
            }
          })
        }
      })
    },


    async getmsg(){
      this.pageloading = true
      try{
        let data = {
          Id:this.$route.query.Id
        }
        let result = await qyweixinqyweixingroupfissionactivitydetail(data)
        if(result.IsSuccess){
          // console.log(result.Result)
          let ruForm = result.Result
          this.getstate(ruForm.IsFinish,ruForm.StartTime,ruForm.EndTime)
          ruForm.AwardReceivingEndTime = 1
          ruForm.StartTime = new Date(ruForm.StartTime)
          ruForm.EndTime = new Date(ruForm.EndTime)
          if(ruForm.AttachmentInfoList&&ruForm.AttachmentInfoList.length){
            ruForm.LinkPicurl = ruForm.AttachmentInfoList[0].ImgUrl
            ruForm.LinkTitle = ruForm.AttachmentInfoList[0].LinkName
            ruForm.LinkDesc = ruForm.AttachmentInfoList[0].Description
          }

          if(ruForm.TaskConfigList&&ruForm.TaskConfigList.length){
            ruForm.TaskConfigList.map((v,i)=>{
                  let canTargetMemberCount = {//只有未开始的活动才可以编辑目标人数  或者进行中新增的阶梯
                    canedit:this.activestate!=0 && !this.$route.query.iscopy,//已开始和已结束且不是复制的活动不能被编辑
                    candel:this.activestate!=1,//已经保存 进行中 的无法被删除
                  }
                  if(i>0){  
                    this.canTargetMemberCount.push(canTargetMemberCount)
                  }else{
                    this.canTargetMemberCount[0] = canTargetMemberCount
                  }
                  v = this.changeTypemsg(v)
                  let allCoupon = {
                    allCouponlist:[],
                    currentPageCoupon:1,
                    sizepageCoupon:5,
                    totalCoupon:null,
                  }
                  if(i>0){
                    this.allCoupon.push(allCoupon)
                  }else{
                    this.allCoupon[0] = allCoupon
                  }
                if(v.ActivityCouponList&&v.ActivityCouponList.length){
                  this.allCoupon[i].allCouponlist=v.ActivityCouponList
                  this.allCoupon[i].totalCoupon=v.ActivityCouponList.length
                  v.ActivityCouponList = this.filtercoupons2(ruForm,i)
                }
                  let allgift = {
                    allgiftlist:[],
                    currentPageGift:1,
                    sizepageGift:5,
                    totalGift:null,
                  }
                  if(i>0){
                    this.allgift.push(allgift)
                  }else{
                    this.allgift[0] = allgift
                  }
                if(v.GiftProductList&&v.GiftProductList.length){
                    this.allgift[i].allgiftlist=v.GiftProductList
                    this.allgift[i].totalGift=v.GiftProductList.length
                  v.GiftProductList = this.filtergifts2(ruForm,i)
                }
                
                  v.random = this.getrandom()
            })
          }
          // $route.query.isedit
          if(this.$route.query.iscopy){
            ruForm.Id = 0
            ruForm.StartTime = ''
            ruForm.EndTime = ''
            this.activestate=0
            ruForm.JoinGroupList = []
            this.noedit = false
            ruForm.IsFinish = false
          }else if(this.activestate==2){
            this.noedit = true
          }else{
            this.noedit = ruForm.IsFinish
          }
          // console.log(ruForm.IsFinish,this.activestate,ruForm.JoinGroupList)
          this.ruForm = ruForm
        }
      }finally{
        this.pageloading = false
      }
    },
    //判断状态
    getstate(IsFinish,StartTime,EndTime){
      if(this.$route.query.iscopy){
        this.activestate=0
      }else if(IsFinish){
        this.activestate =  2
      }else{
          let date = new Date().getTime()
          let starttime = new Date(StartTime).getTime()
          let endtime = new Date(EndTime).getTime()
          // console.log(starttime>date)
          if(starttime>date){
            this.activestate =  0
          }else if(starttime<=date && endtime>date){
            this.activestate =  1
          }else if(endtime<date){
            this.activestate =  2
          }
      }
    },
    //筛选数据  做分页
    filtercoupons2(arr,num){
      // console.log(arr.TaskConfigList[num],1,this.allCoupon[num],2)
      return arr.TaskConfigList[num].ActivityCouponList = this.allCoupon[num].allCouponlist.filter((v,i)=>{
        return i>=(this.allCoupon[num].currentPageCoupon-1)*this.allCoupon[num].sizepageCoupon && i<this.allCoupon[num].currentPageCoupon*this.allCoupon[num].sizepageCoupon
      })
    },
    filtergifts2(arr,num){
      return arr.TaskConfigList[num].GiftProductList = this.allgift[num].allgiftlist.filter((v,i)=>{
        return i>=(this.allgift[num].currentPageGift-1)*this.allgift[num].sizepageGift && i<this.allgift[num].currentPageGift*this.allgift[num].sizepageGift
      })
    },

    //小程序
    async getprolist(){
      let result = await apiList.mcroProgramList();
      // if(result.IsSuccess){
        // console.log(result)
        this.prolist = result
      // }
    },
    //余额充值
    async gettopUpConfigIndex(){
      let result = await topUpConfigIndex()
      if(result.IsSuccess){
        // console.log(result)
        this.IsOpenMallTopUp = result.Result.IsOpenMallTopUp
      }
    },
  }
}
</script>

<style lang = "less" scoped>
  .headFont{
    font-size: 18px;
  }
  .bottomCard{
    position: fixed;
    bottom: 0px;
    height: 80px;
    left: 10px;
    right: 0px;
  }
  .flexRow{
    display: flex;
    flex-direction: row;
  }
  .flexCol{
    display: flex;
    flex-direction: column;
  }
  .colbetween{
    justify-content: space-between;
  }
  .bottomBtn{
    width: 90px;height: 36px;
  }
  .linkFont{
    font-size:12px;margin:0px;padding:0px;line-height:1;
    margin-top: -40px;
  }
  .grayFont{
    font-size: 12px;
    color: #909399;
  }
  .blueBox{
    margin-top: 30px;
    border:1px solid rgb(64, 158, 255);
    background: #FBFDFF;
    padding: 5px;
    display: flex;
    flex-direction: row;
  }
  .blueBoxFont p{
    font-size: 14px;
    line-height: 1;
    color: #606266;
  }
  .blueBoxFont .redFont{
    color:rgb(245,108,108)
  }
  .blueBoxFont .blueFont{
    color:#409eff
  }
  .contenteditableBox{
    width: 800px;
    height: 310px;
    overflow: hidden;
    overflow-y: auto;
    line-height: 1.5;
    padding: 5px;
    border-radius: 3px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  }
  .inputDivplaceholder{
    width: 800px;
    height: 310px;
    /* margin-top: -310px; */
    position: absolute;
    top: 35px;
    left: 0px;
    pointer-events: none;
    padding:10px;
    border-radius: 3px;
  }
  .inputDivplaceholder div{
    font-size: 14px;
    color: #909399;
    overflow: hidden;
    overflow-y: auto;
    line-height: 1.5;
  }
  .contentlength{
    /* border:1px solid black; */
    background: white;
    width:80px;
    height: 20px;
    position: absolute;
    top: 280px;
    right: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #606266;
  }
  .flexcontent{
    justify-content: center;
    align-items: center;
  }
  .garybgc{
    background: #EBEEF5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    font-size: 15px;
  }
  .ladder ::v-deep .el-form-item__label:before{
    content: '*' !important;
    color: #F56C6C !important;
    margin-right: 4px !important;
  }
  .nostar ::v-deep .el-form-item__label:before{
    content: '' !important;
  }
  .formremaining ::v-deep .el-form-item__error{
    left: 2px !important;
    top:165%
  }
  .formremaining2 ::v-deep .el-form-item__error{
    left: 68px !important;
  }
  .formintegral ::v-deep .el-form-item__error{
    left: 25px !important;
  }
  .qrcode-err ::v-deep .el-form-item__error{
    top:140%;
    left: 5px !important;
  }
  .radioFont{
    margin-left: 28px;
    line-height: 1.2;
    font-size: 12px;
    color: #909399;
  }
  .laddercoupons ::v-deep .el-form-item__error{
    left: 27px !important;
  }
  .postersFont p{
    width:400px;
    margin-left: 15px;
    line-height: 1.5;
    font-size: 12px;
    color: #999999;
  }
  .conditionsFont{
    font-size: 14px;
    line-height: 36px;
    font-weight: bold;
    color: #606266;
  }
  .errtips{
    color: #F56C6C;
    font-size:12px;
  }
  .rowbetween{
    justify-content: space-between;
    align-items: center;
  }
  .vertical2{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .grayFont2{
    color: #999999 !important;
  }
  .groupName{
    width:150px;
    line-height: 1.3;
    margin-left:5px;
  }
</style>
<style lang = "less" scoped>
  .groupbox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    flex-wrap: wrap;
    .groupcontentbox{
      border:1px solid #DCDFE6;
      width: 30%;
      height: 200px;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .groupcontent-top{
        border-bottom: 1px dashed #DCDFE6;
        height: 150px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .groupcontent-top-right{
          margin-left:10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          margin-top:0px;
          width:~"calc(100% - 150px)";
          overflow: hidden;
          /* border:1px solid black */
          /* position: absolute; */
        }
      }
    }
  }
  .groupbox:after{
    content: '';
    width: 30%;
    margin-top: 20px;
    /* border:1px solid black */
  }
  .successstyle{
    color: #67C23A;
    background: #f0f9eb;
    padding: 5px 15px;
    font-size: 12px;
    /* margin: 3px; */
    text-align: center;
    line-height: 1;
    border: 1px solid #c2e7b0;
    border-radius: 15px;
    display: inline-block;
    /* position: absolute; */
  }
  .nosuccesss{
    color: #909399;
    background: #f4f4f5;
    border-color: #d3d4d6;
  }
  .errorbtn{
    color: #f56c6c;
    background: #fef0f0;
    border-color: #fbc4c4;
  }
  .font-vertical2{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.3;
    word-break: break-all;
  }
  .flowhidden{
    overflow: hidden;
    width:100%;
  }
  .bottom-redFont{
    width:100%;
    color: #f56c6c;
    font-size: 14px;
  }
  .groupcontent-top-center{
    display: flex;
    flex-direction: row;
    align-items: center;
    height:100%;
    /* border:1px solid black; */
  }
  .groupcontent-garyfont{
    font-size: 14px;
    color: #909399;
    padding: 0px 10px;
    height:50px;
    width:100%;
    /* border:1px solid black; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

  }
  .pushgroup-center{
    color: #409eff;
    font-size: 16px;
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer;
  }
  .isend{
    width:200px !important;
    margin-left:100px
  }
  .redmsg{
    color: #E51C23;font-size: 12px;margin-left:5px
  }
</style>
<style lang = "less" >
  .avatar-uploader .el-upload {
    border: 1px dashed #409eff;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 16px;
    color: #409eff;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
  .avatar {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 118px;
    height: 118px;
    border-radius: 6px;
    display: block;
    pointer-events: none;
    /* z-index: 99; */
  }
  .staffpadding .el-dialog__body{
    padding:20px !important
  }
</style>